<template>
	<div class="img">
		<Nav></Nav>
		<div class="swipe" :class="level>1?'swipe-admin':''">
			<el-carousel :interval="5000" arrow="always" :type="width<770?'':'card'" :autoplay='true' class="swipe-content">
			    <el-carousel-item v-for="(img,index) in pcSwip" :key="index" :style="{'backgroundImage': img.src}">
					<div class="img-content">
						<img v-lazy="img.src" :alt="img.info" preview='0' :preview-text="'画师：'+img.info">
					</div>
			    </el-carousel-item>
			</el-carousel>
		</div>
		<div class="image-form" v-if="level>1">
			<el-form label-position="left" label-width="50px" :model="image" ref='image' :rules="rules">
			  <div class="image-div">
			  	<el-form-item label="分类" prop="type" class="image-type">
			  		<el-select v-model="image.type" class="image-input" placeholder="请选择分类">
			  		  <el-option :label="type==0?'电脑壁纸':'手机壁纸'" :value="type" v-for="(type,index) in types" :key='index'></el-option>
			  		</el-select>
			  	</el-form-item>
			  	<el-form-item label="图片" prop="imgUrl" class="image-imgUrl">
			  	    <el-input v-model="image.imgUrl" class="image-input" placeholder="图片的资源链接,不是出处,需要上传到图床得到链接"></el-input>
			  	</el-form-item>
			  </div>
			  <div class="image-div">
			  	<el-form-item label="画师" prop="artist" class="image-artist">
			  	  <el-input v-model="image.artist" class="image-input" placeholder="画师名"></el-input>
			  	</el-form-item>
			  	<el-form-item label="主页" prop="artistUrl" class="image-artistUrl">
			  	  <el-input v-model="image.artistUrl" class="image-input" placeholder="画师的一个链接,比如P站推特啥的都可以"></el-input>
			  	</el-form-item>
			  </div>
			  <el-form-item label="描述" prop="sup">
			    <el-input v-model="image.sup" class="image-input"  placeholder="对图片进行描述,相当打标签,例如'原创文艺海滩女孩子电脑壁纸'"></el-input>
			  </el-form-item>
			  <el-form-item>
			    <el-button type="primary" @click="submitForm('image')">提交</el-button>
			  </el-form-item>
			</el-form>
		</div>
		<div class="image-search">
			<el-select v-model="type" placeholder="请选择">
			    <el-option label="全部" value="-1"></el-option>
			    <el-option label="电脑壁纸" value="0"></el-option>
				<el-option label="手机壁纸" value="1"></el-option>
			</el-select>
			<el-input
			    placeholder="请输入"
			    v-model="search">
			    <i slot="suffix" class="el-icon-search"></i>
			</el-input>
		</div>
		<div class="image-content" :class="level>1?'image-admin':''" id="image-preview" @mouseover="hover = true">
			<vue-waterfall-easy :imgsArr="imgsArr" ref="waterfall" linkRange='' @mouseover='clickFn' @scrollReachBottom='scrollB' @preloaded='addPre'>
				<div slot="waterfall-head"><h2 style="color: white;margin: 2em auto;">图片墙</h2></div>
				<div class="img-info" slot-scope="props">
					<p class="some-info"><a :href="props.value.href" target="_blank">{{props.index+1}}.{{props.value.info}}</a></p>
				</div>
				<div slot="waterfall-over" style="color: white;">已经到底啦~</div>
			</vue-waterfall-easy>
		</div>
		<MessageC :messages='messages' @getMsg='getMessages' :image='true'></MessageC>
	</div>
</template>

<script>
import vueWaterfallEasy from 'vue-waterfall-easy'
import Nav from '@/components/Nav.vue'
import MessageC from '@/components/Message.vue'
export default {
    metaInfo: {
        title: '图片 - KASUIEの次元',
        meta: [
    	    {
    		    name: 'description',
                content: '图片',
    	    },
            {
                name: 'keywords',
                content: '图片，壁纸，电脑壁纸，手机壁纸，Pixiv，插画'
            },
			{
				name: 'viewport',
				content: 'width=device-width, initial-scale=1,maximum-scale=1, user-scalable=no'
			}
      ]
    },
  name: 'Images',
  components: {
	Nav,
  	vueWaterfallEasy,
	MessageC
  },
  data() {
  	return {
  		imgsArr: [],
		imgTemp: [],
		pc: [],
		pcSwip: [],
		mobile: [],
		scrollNum: 0,
		hover: false,
		level: this.$store.getters.getUser.level,
		types:[0,1],
		type: null,
		search: '',
		width: document.body.offsetWidth,
		messages: [],
		image: {
			type: '',
			imgUrl: '',
			artist: '',
			artistUrl: '',
			sup: '',
		},
		rules: {
			type: [
				{ required: true, message: '请输入分类', trigger: 'blur' },
			],
			imgUrl: [
				{ required: true, message: '请输入图片链接', trigger: 'blur' },
			],
			artist: [
				{ required: true, message: '请输入作者', trigger: 'blur' },
			],
			artistUrl: [
				{ required: true, message: '请输入作者链接', trigger: 'blur' },
			],
			sup: [
				{ required: true, message: '请输入描述内容', trigger: 'blur' },
			],
		}
  	}
  },
  methods: {
  	  getData(){
  		  let that = this
  		  this.axios.post('/images',{}).then(function(resq){
			  that.getMessages()
  			  that.imgTemp = resq.data
			  let pc = []
			  let mobile = []
			  for (let img of that.imgTemp) {
			  	if(img.type==0){
			  		pc.push(img)
			  	}else if(img.type==1){
					mobile.push(img)
				}
			  }
			  let numTemp = []
			  for (let num = 0;numTemp.length < 4; num++) {
				  let index = Math.floor(Math.random()*pc.length)
				  if(numTemp.indexOf(index)==-1){
					  numTemp.push(index)
					  that.pcSwip.push(pc[index])
				  }
			  }
			  that.pc = pc
			  that.mobile = mobile
			  that.scrollB()
  		  },function(err){
  			  console.log(err)
  		  })
  	  },
	  getMessages(){
	  		  let that = this
	  		  this.axios.post('messages',{}).then(function(results){
	  			  that.messages = results.data
	  		  },function(err){
	  			  console.log(err)
	  		  })
	  },
	  clickFn(event, { index, value }){
		  event.preventDefault()
		  if (event.target.tagName.toLowerCase() == 'img') {
			  console.log('img clicked',index, value)
		  }
	  },
	  addPre(){
		 let images = document.getElementById("image-preview")
		 let imgs = images.getElementsByTagName('img') 
		 let imgsArr = this.imgTemp
		 setTimeout(function(){
			 for (let i = 0; i < imgs.length; i++) {
				imgs[i].setAttribute('preview','1')
				imgs[i].setAttribute('preview-text','画师: '+ imgsArr[i].info)
				imgs[i].setAttribute('v-lazy',imgsArr[i].src)
				imgs[i].setAttribute('alt',imgsArr[i].info)
			 }
		 },100)
	  },
	  pre(){
		  this.$previewRefresh()
	  },
	  submitForm(formName) {
	  	this.$refs[formName].validate((valid) => {
	  	  if (valid) {
	  		this.saveImage()
	  	  } else {
	  		console.log('error submit!!');
	  		return false;
	  	  }
	  	});
	  },
	  saveImage(){
		  let that = this
		  this.axios.post('/saveImage',that.image).then(function(results){
			  let img = results.data
			  if(img.info!=null){
				  that.$notify({
				  	title: '成功',
				  	message: '图片添加成功',
				  	type: 'success'
				  });
				  that.imgsArr.push(img)
				  if(img.type==0){
				  	that.pc.push(img)
				  }else if(img.type==1){
				  	that.mobile.push(img)
				  }
				  this.pre()
			  }else{
				  that.$notify.info({
				      title: '失败',
				      message: '图片添加失败'
				  });
			  }
		  },function(err){
			  console.log(err);
		  })
	  },
	  scrollB(){
		  if(this.imgsArr.length==this.imgTemp.length){
			  this.$refs.waterfall.waterfallOver()
		  }else if(this.imgsArr.length+15>this.imgTemp.length){
			  this.imgsArr = this.imgTemp
		  }else{
			  this.imgsArr = this.imgTemp.slice(0,(this.scrollNum+1)*15)
			  this.scrollNum++
		  }
		  this.pre()
	  }
  },
  watch: {
	  hover(){
		  this.pre()
	  },
	  type(){
		  if(this.type==-1){
			  this.imgTemp = this.imgsArr
		  }else if(this.type == 0){
			  this.imgTemp = this.pc
		  }else if(this.type == 1){
			  this.imgTemp = this.mobile
		  }
	  }
  },
  created() {
	this.$store.commit('showLoading')
  	this.getData()
	document.title = 'KASUIEの图片'
	let title
	setTimeout(function() {
	   	title = document.title
	}, 1000);
	window.onfocus = function () {
		document.title = '恢复正常了...';
	    setTimeout(function() {
			document.title = title
		}, 1000);
	};
	window.onblur = function () {
		setTimeout(function() {
			document.title = '快回来~页面崩溃了'
		}, 5000);
	};
  },
  mounted() {
  	window.onresize = () => {
		return (() => {
  	        this.width = document.body.offsetWidth
  	    })()
  	},
	document.body.style.overflow='hidden'
	setTimeout(() => {
		this.$store.commit('showLoading')
		document.body.style.overflow=''
	},3000)
  },
  destroyed() {
  	this.$store.commit('loading')
  }
}
</script>

<style>
.img{
	width: 100%;
	min-height: 100%;
}
.image-content{
	height: 1080px;
	margin-top: 40em;
	margin-bottom: 5em;
}
.image-search{
	position: absolute;
	left: 0;
	right: 0;
	width: 70%;
	text-align: start;
	margin: 0 auto;
	margin-top: -4em;
	display: none;
}
.image-search .el-input:nth-child(even){
	width: auto;
	margin-left: -2em;
	display: none;
}
.image-search .el-input:nth-child(odd){
	/* width: 50%; */
}
.image-search .el-input:nth-child(odd) .el-input__suffix{
	/* right: 50%; */
}
.image-search .el-icon-search{
	line-height: 3;
}
.image-form{
	position: absolute;
	top: 40em;
	width: 100%;
}
.image-form .el-form{
	width: 60%;
	margin: 0 auto;
}
.image-input{
	width: 100%;
}
.image-form .el-form .image-type,.image-imgUrl,.image-artist,.image-artistUrl{
	display: inline-block;
}
.image-div{
	width: 100%;
}
.image-type,.image-artist{
	width: 20%;
}
.image-imgUrl,.image-artistUrl{
	width: 80%;
}
.image-content:after{
    content: "";
    height: 0;
    clear: both;
    overflow: hidden;
    display: block;
    visibility: hidden;
}
.image-hidden{
	width: 100%;
	height: 1px;
	clear: both;
}
.swipe{
	position: relative;
}
.swipe .el-carousel{
	position: absolute;
	top: -35em;
	width: 100%;
}
.image-admin{
	margin-top: 60em;
}
.swipe-admin .el-carousel{
	top: -55em ;
}
.swipe .swipe-content .el-carousel__container{
	height: 400px;
	margin: 0 auto;
}
.el-carousel__indicators--outside button{
	background-color: #1b4eb5;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
}
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
} 
.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}
.img-content{
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.img-content img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.img-info{
	padding: .5em;
}
.vue-waterfall-easy-container{
	padding: 0;
}
.vue-waterfall-easy-container .vue-waterfall-easy a.img-wraper > img{
	cursor: pointer;
}
.some-info a{
	color: #ffffff;
}
@media screen and (max-width: 770px) {
	.swipe .swipe-content .el-carousel__container{
		height: 225px;
	}
	.image-search{
		margin-top: -3em;
	}
	.image-type,.image-artist{
		width: 100%;
	}
	.image-imgUrl,.image-artistUrl{
		width: 100%;
	}
	.image-form{
		top: 23em;
	}
	.image-form .el-form{
		width: 90%;
	}
	.swipe .el-carousel{
		top: -20em;
	}
	.image-content{
		margin-top: 20em;
	}
	.swipe-admin .el-carousel{
		top: -50em;
	}
	.image-admin{
		margin-top: 50em;
	}
}
</style>
